<header class="banner">
  <div class="infos">
    <h1 class="title-banner">VOCÊ QUER TRANSFORMAR A SUA VIDA?</h1>
    <h4 class="subtitle-banner my-4">
      Ter seu <b>próprio negócio</b> e <b>tempo</b> para curtir a vida é possível com a
      Rommanel. Garanta já sua liberdade financeira revendendo a joia banhada que todo mundo ama!
    </h4>
    <h4 class="text-click-here">
      Clique no botão abaixo e transforme sua paixão por joias em um negócio de
      sucesso!
    </h4>
    <a href="pre-cadastro/{{utmsParams}}" class="button-revender mt-4">
      <h1>Quero Revender</h1>
    </a>
  </div>
  <div class="banner-image">
    <img src="https://storage.googleapis.com/api-imagens/imagens/precadastro/landing-pages/banner.jpg" class="img-fluid"
         alt="Revenda Rommanel">
  </div>
</header>
<div class="container-fluid mb-5 d-flex justify-content-center">
  <div class="container">
    <div class="row d-flex flex-column">
      <h1 class=" text-upper text-color-primary text-center fs-1 fw-bold mt-5">
        Vantagens de ser uma <br>
        Consultora Rommanel
      </h1>
      <span class="text-center fs-6 mb-5">Descubra como nossa marca pode transformar a sua vida!</span>
    </div>

    <div class="row row-cols-1 row-cols-md-5 g-3 cards-area">
      <div class="col">
        <div class="card h-100 border-radius-0">
          <div class="card-head card-head-custom border-radius-0">
            <img src="https://storage.googleapis.com/api-imagens/imagens/precadastro/landing-pages/thumb-lucro.png"
                 class="card-img-top border-radius-0" alt="Lucro">
            <h5 class="card-title card-title-custom fs-5">Lucro</h5>
          </div>
          <div class="card-body bg-color-primary">
            <p class="card-text fs-5 px-3 py-3">
              Somos <b>referência</b> nacional no setor de joias banhadas, com uma
              margem de
              <b>
                lucro
                superior
                a 40%
              </b>.
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card h-100 border-radius-0">
          <div class="card-head card-head-custom border-radius-0">
            <img src="https://storage.googleapis.com/api-imagens/imagens/precadastro/landing-pages/thumb-nossos-produtos.png"
                 class="card-img-top border-radius-0" alt="Nossos Produtos">
            <h5 class="card-title card-title-custom fs-5">Nossos <br> Produtos</h5>
          </div>
          <div class="card-body bg-color-primary">
            <p class="card-text fs-5 px-3 py-3">
              <b>Mais de 4 mil modelos</b> de <b>joias hipoalergênicas</b> a níquel,
              ou
              seja,
              não
              causam alergia.
              As nossas joias banhadas possuem a <b>maior camada de ouro do mercado</b> e <b>Prata 925</b> maciça com
              banho de prata pura.
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card h-100 border-radius-0">
          <div class="card-head card-head-custom border-radius-0">
            <img src="https://storage.googleapis.com/api-imagens/imagens/precadastro/landing-pages/thumb-reconhecimento.png"
                 class="card-img-top border-radius-0" alt="Reconhecimento">
            <h5 class="card-title card-title-custom fs-5">Reconhecimento</h5>
          </div>
          <div class="card-body bg-color-primary">
            <p class="card-text fs-5 px-3 py-3">
              Diversas <b>promoções</b>, <b>eventos</b> e <b>premiações</b> exclusivas
              para as
              consultoras ao longo
              do
              ano.
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card h-100 border-radius-0">
          <div class="card-head card-head-custom border-radius-0">
            <img src="https://storage.googleapis.com/api-imagens/imagens/precadastro/landing-pages/thumb-acoes-de-marketing.png"
                 class="card-img-top border-radius-0" alt="Ações de Marketing">
            <h5 class="card-title card-title-custom fs-5">Ações de <br> Marketing</h5>
          </div>
          <div class="card-body bg-color-primary">
            <p class="card-text fs-5 px-3 py-3">
              Forte <b>presença no digital</b> e em diversos canais de mídia.
              Lançamento
              de
              Coleções
              durante o ano todo que seguem as maiores tendências de moda, além das Coleções assinadas por
              <b>celebridades</b>,
              como <b>Ana Hickmann</b> e <b>Gio Antonelli</b>.
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card h-100 border-radius-0">
          <div class="card-head card-head-custom border-radius-0">
            <img src="https://storage.googleapis.com/api-imagens/imagens/precadastro/landing-pages/tspn-02-FERRAMENTAS-GRATUITAS-02.png"
                 class="card-img-top border-radius-0" alt="Ferramentas Gratuitas">
            <h5 class="card-title card-title-custom fs-5">Ferramentas <br> Gratuitas</h5>
          </div>
          <div class="card-body bg-color-primary">
            <p class="card-text fs-5 px-3 py-3">
              - <b>Loja online exclusiva</b> (compras no conforto da sua casa);
              - <b>App Meu Mundo Rommanel</b> (gestão do seu negócio);
              - <b>Academia Rommanel</b> (plataforma exclusiva de treinamentos);
              - <b>Materiais de apoio</b>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid bg-como-se-tornar-consultora-passo-1 pb-5 pt-5 mt-5 d-flex justify-content-center stamp">
  <div class=" container">
    <h1 class="text-center text-white">COMO FAÇO PARA ME TORNAR</h1>
    <h1 class="text-center text-white">UMA CONSULTORA ROMMANEL</h1>
    <div class="row mt-5 d-flex align-items-center justify-content-center stamp-flex">
      <div class="col-md-3">
        <p class="text-como-se-tornar-consultora">
          <img src="https://storage.googleapis.com/api-imagens/imagens/precadastro/landing-pages/revenda-consultora-rommanel-passo-1.png"
               alt="" width="200px">
        </p>
      </div>
      <div class="col-md-7">
        <p class="text-white font-weight-bold paragrafo-consultora-rommanel text-como-se-tornar-consultora">
          01º
          passo <br> Pré-cadastro
        </p>
        <p class="text-como-se-tornar-consultora">
          <a _ngcontent-cql-c107=""
             class="btn btn-light btn-consultora-rommanel" href="/"> Clique aqui</a>
        </p>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid bg-como-se-tornar-consultora-passo-2 pt-5 pb-5 d-flex justify-content-center stamp">
  <div class="container">
    <div class="row d-flex align-items-center justify-content-center stamp-flex">
      <div class="col-md-3">
        <p class="text-como-se-tornar-consultora">
          <img _ngcontent-cql-c107=""
               src="https://storage.googleapis.com/api-imagens/imagens/precadastro/landing-pages/revenda-consultora-rommanel-passo-2.png"
               alt="" width="200px">
        </p>
      </div>
      <div class="col-md-7">
        <p class="text-white font-weight-bold paragrafo-consultora-rommanel text-como-se-tornar-consultora">
          02º passo
          <br _ngcontent-cql-c107=""> Confirmação do cadastro
        </p>
        <p class="text-white text-como-se-tornar-consultora">
          Você receberá um e-mail de
          confirmação do cadastro com LOGIN e SENHA para acessar todas as nossas plataformas digitais:
        </p>
        <p class="text-white font-weight-bold text-como-se-tornar-consultora">
          LOJA ONLINE | APP
          MEU MUNDO ROMMANEL | ACADEMIA ROMMANEL
        </p>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid bg-como-se-tornar-consultora-passo-3 pt-5 pb-5 d-flex justify-content-center stamp">
  <div class="container">
    <div class="row d-flex align-items-center justify-content-center stamp-flex">
      <div class="col-md-3">
        <p class="text-como-se-tornar-consultora">
          <img _ngcontent-cql-c107=""
               src="https://storage.googleapis.com/api-imagens/imagens/precadastro/landing-pages/revenda-consultora-rommanel-passo-3.png"
               alt="" width="200px">
        </p>
      </div>
      <div class="col-md-7">
        <p class="text-white font-weight-bold paragrafo-consultora-rommanel text-como-se-tornar-consultora">
          03º passo
          <br> Seu primeiro pedido
        </p>
        <p class="text-white text-como-se-tornar-consultora">
          Aguarde o contato da loja
          distribuidora escolhida ou realize seu <b>PRIMEIRO PEDIDO</b> pela <b>LOJA ONLINE</b>, utilizando
          <b>LOGIN</b> e <b>SENHA</b> enviados
          no e-mail de confirmação do cadastro.
        </p>
        <br>
        <p class="text-white text-como-se-tornar-consultora">
          Caso prefira, você também poderá
          entrar em contato via telefone com a loja.
        </p>
        <a class="text-white font-weight-bold text-como-se-tornar-consultora">
          CLIQUE AQUI PARA
          LOCALIZAR A LOJA
        </a>
        <br>
        <p class="text-white text-como-se-tornar-consultora">
          Obs: Verifique a disponibilidade
          da Loja Online para a loja distribuidora escolhida.
        </p>
        <p class="text-white font-weight-bold text-como-se-tornar-consultora">
          Dúvidas? Fale com
          a gente pelo revenda&#64;rommanel.com.br
        </p>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid pt-5 pb-5 d-flex justify-content-center">
  <div class="container">
    <h1 class="text-center text-upper fw-bold my-5">
      Consultoras que transformaram <br>
      suas vidas com Rommanel:
    </h1>
    <div class="row row-cols-1 row-cols-md-4 d-flex justify-content-center consultants-area">
      <div class="col mb-4">
        <div class="card h-100 border-radius-bottom">
          <img src="https://storage.googleapis.com/api-imagens/imagens/precadastro/landing-pages/revenda-depoimentos-odineide.png" alt="..."
               class="card-img-top">
          <div class="card-body bg-card-depoimentos border-radius-bottom px-3 py-3">
            <h5 class="card-title text-center text-white">ODINEIDE</h5>
            <p class="sub-titulo-depoimentos text-white text-center">Consultora Rommanel</p>
            <p class="sub-titulo-depoimentos text-white text-center">há mais de 19 anos</p>
            <p class="card-text text-white text-center mt-3">
              "Conquistei minha casa própria
              revendendo Rommanel."
            </p>
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card h-100 border-radius-bottom">
          <img src="https://storage.googleapis.com/api-imagens/imagens/precadastro/landing-pages/revenda-depoimentos-ana-patricia.png"
               alt="..." class="card-img-top">
          <div class="card-body bg-card-depoimentos border-radius-bottom px-3 py-3">
            <h5 class="card-title text-center text-white">ANA PATRÍCIA</h5>
            <p class="sub-titulo-depoimentos text-white text-center">Consultora Rommanel</p>
            <p class="sub-titulo-depoimentos text-white text-center">há mais de 7 anos</p>
            <p class="card-text text-white text-center mt-3">
              "Minha maior conquista foi minha
              independência financeira e conquistar minha casa."
            </p>
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card h-100 border-radius-bottom">
          <img src="https://storage.googleapis.com/api-imagens/imagens/precadastro/landing-pages/revenda-depoimentos-ivana.png" alt="..."
               class="card-img-top">
          <div class="card-body bg-card-depoimentos border-radius-bottom px-3 py-3">
            <h5 class="card-title text-center text-white">IVANA</h5>
            <p class="sub-titulo-depoimentos text-white text-center">Consultora Rommanel</p>
            <p class="sub-titulo-depoimentos text-white text-center">há mais de 3 anos</p>
            <p class="card-text text-white text-center mt-3">
              "Revendendo Rommanel eu paguei a minha
              pós-graduação."
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid bg-esta-esperando-o-que">
  <div id="esperando-o-que" class="row d-flex align-items-center justify-content-center">
    <div class="col-md-3">
      <img src="https://storage.googleapis.com/api-imagens/imagens/precadastro/landing-pages/revenda-esta-esperando-o-que.png" alt=""
           class="img-esperando-o-que">
    </div>
    <div class="col-md-6">
      <p class="paragrafo-esperando-o-que text-center">
        Está esperando o que para <br><b> transformar a sua vida?</b>
      </p>
      <p class="text-center">
        <a href="pre-cadastro/{{utmsParams}}" class="btn btn-esta-esperando-o-que text-white" href="/">
          Faça
          seu cadastro
        </a>
      </p>
    </div>
    <div class="col-md-2">
    </div>
  </div>
</div>

