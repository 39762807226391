import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SejaUmaConsultoraComponent } from './seja-uma-consultora/seja-uma-consultora.component';
import { LadingPagesRoutingModule } from './landing-page.route';
import { RouterModule } from '@angular/router';
import { LandingPageAppComponent } from './landing-page.app.component';



@NgModule({
  declarations: [
    LandingPageAppComponent,
    SejaUmaConsultoraComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LadingPagesRoutingModule,
  ]
})
export class LandingPageModule { }
