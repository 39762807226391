import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { BaseService } from '../../services/base.service';
@Component({
  selector: 'app-seja-uma-consultora',
  templateUrl: './seja-uma-consultora.component.html',
  styleUrls: ['./seja-uma-consultora.component.scss']
})
export class SejaUmaConsultoraComponent implements OnInit {

  constructor(private route: ActivatedRoute, private titleService: Title, private baseService: BaseService) {
    this.titleService.setTitle("Seja um consultor de sucesso");
  }  

  utmsParams: string | undefined;

  ngOnInit(): void {
    this.PostStep();
    if (this.route.snapshot.queryParams['utm_source'] || this.route.snapshot.queryParams['utm_medium'] || this.route.snapshot.queryParams['utm_campaign']) {
      this.utmsParams = `?utm_source=${this.route.snapshot.queryParams['utm_source']??""}&utm_medium=${this.route.snapshot.queryParams['utm_medium']??""}&utm_campaign=${this.route.snapshot.queryParams['utm_campaign']??""}`;
    }
  }  

  PostStep(){    
    let step = this.baseService.ObterStepLocalStorage("/seja-uma-consultora")
    this.baseService.postStepConsultant(step).subscribe();
  }
}
