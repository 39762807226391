<article id="agendamento">
  @if (spinner) {<app-spinner></app-spinner>}
  <div class="container container-page justify-content-center pb-5">
    <form [formGroup]="agendamentoForm" (ngSubmit)="onSubmit()">
      <div class="col">
        <section class="col-md-12">
          <div>
            <h1 class="text-center pt-5 mb-4">Agendamento</h1>
            <p>
              Olá {{ agendamento?.nome }}, você está sendo atendida pela loja {{ loja?.nomeComercial }}.
            </p>
            <p>
              Cadastre o seu agendamento com a nossa loja.
            </p>
          </div>
        </section>
        <section class="col">
          <div class="shadow p-4 mb-4">
            <p>Quer agendar uma visita à loja física ou reunião online?
              <span class="text-danger"> *</span>
              <span *ngIf="agendamentoForm.controls['tipo'].invalid && agendamentoForm.controls['tipo'] && (agendamentoForm.controls['tipo'].dirty || agendamentoForm.controls['tipo'].touched)">
                <small class="text-danger"> Obrigatório!</small>
              </span>
            </p>
            <div class="form-check">
              <input class="form-check-input" [checked]="agendamentoForm.controls['tipo'].value == 1" type="radio" id="visitaLoja" [value]="1" formControlName="tipo" />
              <label class="form-check-label" for="visitaLoja">
                Visita à loja física ({{ loja?.endereco | titlecase }} {{ loja?.numero }}, bairro {{ loja?.bairro | titlecase }}, {{ loja?.cidade | titlecase }}/{{ loja?.estado | titlecase }}, CEP {{ loja?.cep }})
              </label>
            </div>
            <div class="form-check position-relative">
              <input class="form-check-input" [checked]="agendamentoForm.controls['tipo'].value == 2" type="radio" id="reuniaoOnline" [value]="2"
                formControlName="tipo" />
              <label class="form-check-label" for="reuniaoOnline">
                Reunião online
              </label>
            </div>
          </div>
        </section>
        <section class="col">
          <div class="shadow p-4 mb-4">
            <p>Escolha o dia e horário de sua preferência (segunda a sexta das 9h às 18h, ou sábado das 9h às 13h):
              <span class="text-danger"> *</span>
              <span *ngIf="agendamentoForm.controls['dataAgendamento'].invalid && agendamentoForm.controls['dataAgendamento'] && (agendamentoForm.controls['dataAgendamento'].dirty || agendamentoForm.controls['dataAgendamento'].touched)">
                <small class="text-danger"> Obrigatório!</small>
              </span>
            </p>
            <input type="datetime-local" class="input--date col-md-4" formControlName="dataAgendamento">
          </div>
        </section>
        <section class="col">
          <div class="shadow p-4 mb-4">
            <p>Qual a melhor forma de contato?
              <span class="text-danger"> *</span>
              <span *ngIf="agendamentoForm.controls['formaContato'].invalid && 
              (agendamentoForm.controls['formaContato'].dirty || agendamentoForm.controls['formaContato'].touched)">
                <small class="text-danger">Obrigatório!</small>
              </span>
              <span *ngIf="agendamentoForm.controls['formaContatoOutro'].enabled &&
                          agendamentoForm.controls['formaContatoOutro'].invalid && 
                          (agendamentoForm.controls['formaContatoOutro'].dirty || agendamentoForm.controls['formaContatoOutro'].touched)">
                <small class="text-danger">Obrigatório!</small>
              </span>
            </p>
            <div class="form-check">
              <input class="form-check-input" type="radio" id="telefone" [value]="1" formControlName="formaContato" [checked]="agendamentoForm.controls['formaContato'].value == 1"/>
              <label class="form-check-label" for="telefone">
                Telefone
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" id="whatsapp" [value]="2"
                formControlName="formaContato" [checked]="agendamentoForm.controls['formaContato'].value == 2"/>
              <label class="form-check-label" for="whatsapp">
                WhatsApp
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" id="formaContatoOutro" [value]="3"
                formControlName="formaContato" [checked]="agendamentoForm.controls['formaContato'].value == 3"/>
              <label class="form-check-label" for="formaContatoOutro">
                Outro
              </label>
              <input class="input-line ms-2" type="text" formControlName="formaContatoOutro">
            </div>
          </div>
        </section>
        <section class="col">
          <div class="shadow p-4 mb-4">
            <p>Confirme o número de telefone:
              <span class="text-danger"> *</span>
              <span *ngIf="agendamentoForm.controls['telefone'].invalid && agendamentoForm.controls['telefone'] && (agendamentoForm.controls['telefone'].dirty || agendamentoForm.controls['telefone'].touched)">
                <small class="text-danger"> Obrigatório!</small>
              </span>
            </p>
            <input class="input-line ms-2 col-md-4" type="text" formControlName="telefone" mask="(00) 0 0000-0000"></div>
        </section>
      </div>
      <div class="submit-button-container">
        <input class="submit-button" type="submit" name="submit" value="Agendar"
        [class.valid-form]="agendamentoForm.valid"
        [class.invalid-form]="!agendamentoForm.valid">
      </div>
    </form>
  </div>
</article>