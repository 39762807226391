import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpErrorResponse,
  HttpClient,
  HttpParams,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, isEmpty, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Dispenser } from './models/dispenser';
import { ClienteExistente, ConsultantRegistration, InfoComplementaresRegistration, Step } from './models/consultant';
import { LocalStorageService } from './local-storage.service';
import { Guid } from 'guid-typescript';
import { Agendamento } from './models/agendamento';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  cepApi: string = environment.apiUrl.address.v1.cep;
  consultantApi: string = environment.apiUrl.consultant.v3.consultant;
  clienteApi: string = environment.apiUrl.consultant.v3.cliente;
  dadosComplementaresApi: string = environment.apiUrl.consultant.v3.dadosComplementares;
  dispenserV3Api: string = environment.apiUrl.consultant.v3.dispenser;

  constructor(private http: HttpClient, private localStorageService:LocalStorageService<Step>) {}

  protected GetHeaderJson() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  }

  protected extractData(response: any) {
    return response.data || {};
  }

  protected serviceError(response: Response | any) {
    let customError: string[] = [];
    let customResponse = { error: { errors: [] as string[] } };
  
    if (response instanceof HttpErrorResponse) {
      if (response.statusText === 'Unknown Error') {
        customError.push('Ocorreu um erro desconhecido');
        response.error.errors = customError;
      }
    }
    if (response.status === 500) {
      customError.push(
        'Ocorreu um erro no processamento, tente novamente mais tarde ou contate o nosso suporte.'
      );
  
      customResponse.error.errors = customError;
      return throwError(customResponse);
    }
  
    console.error(response);
    return throwError(response);
  }

  getCepConsult(cep: string): Observable<any> {
    return this.http
      .get<any>(`${this.cepApi}CEP/${cep}`)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getDispenser(uf: string): Observable<Dispenser[]> {
    return this.http
      .get<Dispenser[]>(`${this.dispenserV3Api}uf/${uf}?cadastro=true`)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getClienteExistente(cpf: string, email: string): Observable<ClienteExistente> {
    return this.http
      .get<ClienteExistente>(`${this.clienteApi}CadastroExistente?cpf=${cpf}&email=${email}`)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  postConsultant(consultant: ConsultantRegistration): Observable<object> {
    return this.http
    .post<ConsultantRegistration>(
      `${this.consultantApi}`,
      consultant,
      this.GetHeaderJson()
    )
    .pipe(map(this.extractData), catchError(this.serviceError));
  }

  putInfoComplementaresRegistration(infoComplementares: InfoComplementaresRegistration): Observable<object> {
    return this.http
    .put<InfoComplementaresRegistration>(
      `${this.dadosComplementaresApi}`,
      infoComplementares,
      this.GetHeaderJson()
    )
    .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getDispenserById(id: number): Observable<Dispenser> {
    return this.http
      .get<Dispenser>(`${this.dispenserV3Api}${id}`)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getDispenserIsEcommerce(dispenserId: number): Observable<boolean> {
    return this.http
      .get<any>(`${this.dispenserV3Api}Ecommerce?codigocliente=${dispenserId}`)
      .pipe(
        map((e) => e.data),
        catchError(this.serviceError)
      );
  }

  postStepConsultant(step: Step): Observable<{}> {    
    return this.http
      .post<any>(
        `${this.consultantApi}step`,
        step,
        this.GetHeaderJson()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  ObterStepLocalStorage(stepName:string): Step | null {    
    let stepLocal = this.localStorageService.getItem("step")

    if(stepLocal === null || stepLocal === undefined){
      let id = Guid.create();
      stepLocal = {id: id.toString(), stepName:`${stepName}`}
      this.localStorageService.setItem("step", stepLocal);    
    }else{
      stepLocal.stepName = `${stepName}`;
    }
    
    return stepLocal;    
  }

  getAgendamento(guidId: string): any {
    return this.http
    .get<any>(`${this.clienteApi}agendamento/${guidId}`)
    .pipe(
      map((e) => e.data),
      catchError(this.serviceError)
    );
  }

  postAgendamento(agendamento: Agendamento): any {    
    return this.http
      .post<any>(
        `${this.clienteApi}agendamento/`,
        agendamento,
        this.GetHeaderJson()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
}
