import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { BaseService } from '../services/base.service';
import { NgxMaskDirective } from 'ngx-mask';
import { UtilsModule } from '../utils/utils.module';
import { CepConsult } from '../services/models/cepConsult';
import { Dispenser } from '../services/models/dispenser';
import { Subject, debounceTime, timeout } from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConsultantRegistration } from '../services/models/consultant';
import { ValidationMessages } from './validation-messages';
import { Title } from "@angular/platform-browser";
import {
  cpfValidator,
  dataValid,
  passwordMatchValidator,
  rangeLengthValidator,
  validNameValidator,
  validPass,
} from '../utils/generic-validator';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-pre-cadastro',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgxMaskDirective,
    UtilsModule,
    RouterModule,
    CommonModule,
  ],
  templateUrl: './pre-cadastro.component.html',
  styleUrl: './pre-cadastro.component.scss',
})
export class PreCadastroComponent implements OnInit {
  spinner = false;
  preCadastroForm!: FormGroup;
  @ViewChild('numeroEl') numeroEl!: ElementRef<HTMLInputElement>;
  @ViewChild('cepEl') cepEl!: ElementRef<HTMLInputElement>;
  dispensers!: Dispenser[];
  filteredDispensers!: Dispenser[];
  dispensersCities!: string[];
  validationMessages = ValidationMessages;
  lastCep: string;
  private cepChanged = new Subject<string>();
  hideDataNascimento: boolean = false;

  constructor(
    private fb: FormBuilder,
    private baseService: BaseService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private titleService: Title
  ) {


    this.titleService.setTitle("Pre-cadastro");
  }

  ngOnInit() {
    this.initForm();
    this.PostStep();
    this.cepChanged.pipe(debounceTime(1000)).subscribe(() => {
      this.getCepConsult();
    });
  }

  PostStep(){    
    let step = this.baseService.ObterStepLocalStorage("/pre-cadastro-entrada")
    this.baseService.postStepConsultant(step).subscribe();
  }

  PostStepSubmit(cpf: string, email: string){    
    let step = this.baseService.ObterStepLocalStorage("/pre-cadastro-saida")
    step.cpf = cpf;
    step.email = email;
    this.baseService.postStepConsultant(step).subscribe();
  }

  initForm() {
    this.preCadastroForm = this.fb.group({
      nome: [
        '',
        [
          Validators.required,
          rangeLengthValidator(2, 50),
          validNameValidator(),
        ],
      ],
      sobrenome: ['', [Validators.required, rangeLengthValidator(2, 50)]],
      cpf: ['', [Validators.required, cpfValidator()]],
      dataNascimento: ['', [Validators.required, dataValid()]],
      senha: ['', [Validators.required, rangeLengthValidator(6, 15), validPass()]],
      confirmaSenha: ['', [Validators.required, rangeLengthValidator(6, 15)]],
      email: [
        '',
        [Validators.required, Validators.email, rangeLengthValidator(1, 100)],
      ],
      celular: ['', [Validators.required, rangeLengthValidator(11, 11)]],
      cep: ['', Validators.required],
      rua: ['', [Validators.required, rangeLengthValidator(1, 100)]],
      numero: ['', [Validators.required, rangeLengthValidator(1, 10)]],
      complemento: ['', rangeLengthValidator(1, 50)],
      bairro: ['', [Validators.required, rangeLengthValidator(1, 50)]],
      cidade: ['', rangeLengthValidator(1, 50)],
      uf: [''],
      cidadeLoja: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      loja: new FormControl({ value: '', disabled: true }, Validators.required),
      aceiteTermos: [false, Validators.requiredTrue],
      aceiteOferta: [false],
      utMs: [null],
    }, { validators: passwordMatchValidator });

    this.captureUtms();

    this.preCadastroForm.controls['cidadeLoja'].valueChanges.subscribe(
      (selectedCity) => {
        if (selectedCity) {
          this.filteredDispensers = this.dispensers.filter(
            (dispenser) => dispenser.cidade === selectedCity
          );
          this.shuffleDispensers();
          this.preCadastroForm.controls['loja'].enable();
          this.selectSingleDispenser();
        } else {
          this.preCadastroForm.controls['loja'].disable();
        }
      }
    );
  }

  shuffleDispensers() {
    for (let i = this.filteredDispensers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.filteredDispensers[i], this.filteredDispensers[j]] = [this.filteredDispensers[j], this.filteredDispensers[i]];
    }
  }

  captureUtms() {
    if (
      this.route.snapshot.queryParams['utm_source'] ||
      this.route.snapshot.queryParams['utm_medium'] ||
      this.route.snapshot.queryParams['utm_campaign']
    ) {
      this.preCadastroForm.patchValue({
        utMs: {
          utmSource: this.route.snapshot.queryParams['utm_source']??"",
          utmMedium: this.route.snapshot.queryParams['utm_medium']??"",
          utmCampaign: this.route.snapshot.queryParams['utm_campaign']??"",
        },
      });
    }
  }

  showHidePass(idInput: string, idIcon: string): void {
    let input = <HTMLInputElement>document.getElementById(idInput);
    let icon = <HTMLInputElement>document.getElementById(idIcon);

    input.type === 'password'
      ? (input.type = 'text')
      : (input.type = 'password');

    icon.className === 'fa fa-eye-slash'
      ? (icon.className = 'fa fa-eye')
      : (icon.className = 'fa fa-eye-slash');
  }

  onCepBlur($event: any) {
    this.cepChanged.next($event);
  }

  getCepConsult() {
    const cepControl = this.preCadastroForm.get('cep');
    if (!cepControl) return;
    const cepValue = cepControl.value.replace(/\D/g, '');
    if (cepValue.length < 8) return;
    if (cepValue == this.lastCep) return;
    this.lastCep = cepValue;

    this.spinner = true;

    this.baseService.getCepConsult(cepValue).subscribe({
      next: (response) => {
        this.spinner = false;
        this.fillAddressConsult(response);
        this.loadDispensers();
        this.numeroEl.nativeElement.focus();
      },
      error: (erro) => {
        this.spinner = false;
        this.cepEl.nativeElement.focus();
        this.processApiValidationError(erro);
      },
      complete: () => {},
    });
  }

  fillAddressConsult(response: CepConsult) {
    this.preCadastroForm.patchValue({
      bairro: response.bairro,
      cep: response.cep,
      rua: response.logradouro,
      cidade: `${response.cidade}`,
      uf: `${response.uf}`,
    });
  }

  clearAddressConsult() {
    this.preCadastroForm.patchValue({
      cep: '',
      cidade: '',
      uf: '',
      cidadeLoja: '',
      loja: '',
    });
    this.dispensersCities = [];
    this.dispensers = [];
    this.filteredDispensers = [];
  }

  loadDispensers() {
    this.spinner = true;
    const ufControl = this.preCadastroForm.controls['uf'];
    this.baseService.getDispenser(ufControl.value).subscribe({
      next: (response) => {
        this.dispensers = response;
        this.loadDispensersCities();
        this.spinner = false;
      },
      error: (erro) => {
        this.dispensers = [];
        this.spinner = false;
      },
    });
  }

  loadDispensersCities() {
    this.dispensersCities = [
      ...new Set(this.dispensers.map((dispenser) => dispenser.cidade)),
    ];
    this.dispensersCities.sort((a, b) => a.localeCompare(b));
    if (this.dispensersCities) {
      this.loadFilteredDispensers();
    } else {
      this.preCadastroForm.controls['cidadeLoja'].disable();
    }
  }

  loadFilteredDispensers() {
    this.preCadastroForm.controls['cidadeLoja'].enable();
    const cidadeValue = this.preCadastroForm.get('cidade').value?.toLowerCase();

    if (cidadeValue) {
      const foundCity = this.dispensersCities.find(
        (city) => city.toLowerCase() === cidadeValue
      );
      if (foundCity) {
        this.preCadastroForm.get('cidadeLoja').setValue(foundCity);
        // selectSingleDispenser(foundCity);
      } else {
        this.preCadastroForm.get('cidadeLoja').setValue('');
      }
    } else {
      this.preCadastroForm.get('cidadeLoja').setValue('');
    }
  }

  selectSingleDispenser() {
    if (this.filteredDispensers.length == 1) {
      this.preCadastroForm
        .get('loja')
        .setValue(this.filteredDispensers[0].codigoCliente);
    }
  }

  displayErrorMessage(formControlName: string): string | null {
    const formControl = this.preCadastroForm.get(formControlName);

    if (formControl && formControl.touched && formControl.errors) {
      const errors = formControl.errors;

      for (const errorType of Object.keys(errors)) {
        if (this.validationMessages[formControlName]?.[errorType]) {
          return this.validationMessages[formControlName][errorType];
        }
      }
    }

    return null;
  }

  openModal(content: any): void {
    this.modalService.open(content, { size: 'xl' });
  }

  transformFormToConsultantRegistration(
    formValue: any
  ): ConsultantRegistration {
    return {
      distribuidorId: formValue.loja,
      utMs: {
        utmSource: formValue.utMs?.utmSource || '',
        utmMedium: formValue.utMs?.utmMedium || '',
        utmCampaign: formValue.utMs?.utmCampaign || '',
      },
      senha: formValue.senha,
      confirmaSenha: formValue.confirmaSenha,
      aceiteTermos: formValue.aceiteTermos,
      aceiteOferta: formValue.aceiteOferta,
      nome: formValue.nome.trim(),
      sobrenome: formValue.sobrenome.trim(),
      dataNascimento: formValue.dataNascimento,
      cpf: formValue.cpf,
      email: formValue.email,
      telefone: {
        codigoPais: 55, // default
        numero: formValue.celular,
        tipoTelefone: 1, // default
        whatsapp: true, // default
      },
      endereco: {
        cep: formValue.cep,
        rua: formValue.rua,
        numero: formValue.numero,
        complemento: formValue.complemento,
        bairro: formValue.bairro,
        estado: formValue.uf,
        municipio: formValue.cidade,
        pais: 'Brasil', // default
        apelido: 'Principal', // default
        principal: true, // default
      },
    };
  }

  processApiValidationError(errorResponse: any) {
    if (errorResponse && errorResponse.error && errorResponse.error.errors) {
      const topLevelErrors = errorResponse.error.errors;

      if (topLevelErrors.errors && topLevelErrors.errors.DomainNotification) {
        const domainErrors = topLevelErrors.errors.DomainNotification;

        if (Array.isArray(domainErrors)) {
          domainErrors.forEach((message: string) => {
            this.toastr.error(message);
            if (message === 'Cep invalido!') {
              this.clearAddressConsult();
            }
          });
        } else if (typeof domainErrors === 'object') {
          Object.keys(domainErrors).forEach((key) => {
            const errorMessages = domainErrors[key];
            if (Array.isArray(errorMessages)) {
              errorMessages.forEach((message) => {
                this.toastr.error(message);
                if (
                  message === 'Consultora já cadastrada com este email.' ||
                  message === 'Cadastro já existente com este CPF.'
                ) {
                  this.redirectConsultoraJaCadastrada();
                }
              });
            }
          });
        }
      } else if (typeof topLevelErrors === 'object') {
        Object.keys(topLevelErrors).forEach((key) => {
          const errorMessages = topLevelErrors[key];
          if (Array.isArray(errorMessages)) {
            errorMessages.forEach((message) => {
              this.toastr.error(message);
              if (message === 'Cep invalido!') {
                this.clearAddressConsult();
              }
              if (
                message === 'Consultora já cadastrada com este email.' ||
                message === 'Cadastro já existente com este CPF.'
              ) {
                this.redirectConsultoraJaCadastrada();
              }
            });
          }
        });
      } else if (typeof topLevelErrors === 'string') {
        this.toastr.error(topLevelErrors);
      } else {
        this.toastr.error('Erro desconhecido ao processar a validação');
      }
    } else {
      this.toastr.error('Erro desconhecido ao processar a validação.');
    }
  }

  redirectConsultoraJaCadastrada() {
    this.spinner = true;
    this.router.navigate(['/cadastro-existente']);
  }

  processApiSuccess(message: string) {
    this.toastr.success(message, 'Sucesso!');
  }

  onSubmit() {
    const aceiteTermosFormControl = this.preCadastroForm.get('aceiteTermos');
    let canSubmit = true;

    if (aceiteTermosFormControl.errors) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.toastr.error(
        'Você deve aceitar os termos de uso para prosseguir.',
        'Erro'
      );
      canSubmit = false;
    }

    if (this.preCadastroForm.valid) {
      if (!canSubmit) return;
      this.spinner = true;
      const consultantRegistration = this.transformFormToConsultantRegistration(
        this.preCadastroForm.value
      );
      sessionStorage.setItem('email', consultantRegistration.email??"");
      sessionStorage.setItem('nome', consultantRegistration.nome??"");
      sessionStorage.setItem('cpf', consultantRegistration.cpf??"");
      sessionStorage.setItem('distribuidorId', consultantRegistration.distribuidorId.toString());

      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.baseService.postConsultant(consultantRegistration).subscribe({
        next: (response) => {
            this.spinner = false;
            const id = response;
            const email = consultantRegistration.email;
            const telefone = consultantRegistration.telefone;
            this.processApiSuccess('Complemente seu cadastro.');
            this.PostStepSubmit(consultantRegistration.cpf, email)
            sessionStorage.setItem('id', id.toString()),
            sessionStorage.setItem('email', email),
            sessionStorage.setItem('phone', `+${telefone.codigoPais}${telefone.numero}`),
            sessionStorage.setItem('nome', consultantRegistration.nome),
            sessionStorage.setItem('cpf', consultantRegistration.cpf),
            sessionStorage.setItem('distribuidorId', consultantRegistration.distribuidorId.toString()),
            setTimeout(() => {
              this.router.navigate(['/segunda-etapa/'], {
                queryParams: { id: id, email: email },
              });
            }, 2000);
        },
        error: (errorResponse) => {
          this.spinner = false;          
          this.processApiValidationError(errorResponse);
        },
      });
    } else {
      this.preCadastroForm.markAllAsTouched();
    }
  }

  triggerHidden() {
    this.hideDataNascimento = true;
  }
}
