import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PreCadastroComponent } from './pre-cadastro/pre-cadastro.component';
import { UtilsModule } from './utils/utils.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LandingPageModule } from './landing-page/landing-page.module';
import { AgendamentoComponent } from './agendamento/agendamento.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterOutlet,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LandingPageModule,
    BrowserAnimationsModule,
    UtilsModule,
    HeaderComponent,
    FooterComponent,
    PreCadastroComponent,
    AgendamentoComponent,
    NgbModule,
    RouterModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      timeOut: 3000,
      positionClass: 'toast-top-right',
    }),
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
